import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@rsuite/icons";
import { MdCheck } from "react-icons/md";
import CloseIcon from '@rsuite/icons/Close';
import { DopeToolTip } from "./DopeToolTip";

import { humanize } from "../utils/railsNames";
import './DopePill.scss';

const pillClassSetter = (pillType) => {
  if (['green', 'paid', 'complete', 'success', 'approved', 'printing_complete'].includes(pillType)) {
    return 'success';
  } else if (['blue', 'active', 'sent', 'shipped', 'delivered'].includes(pillType)) {
    return 'active';
  } else if (['purple', 'scheduled'].includes(pillType)) {
    return 'scheduled';
  } else if (['yellow', 'pending', 'processing', 'pending_payment', 'pending_payment_method', 'pending_action', 'needs_approval', 'manual approval', 'validation mode', 'printing', 'in_progress'].includes(pillType)) {
    return 'pending';
  } else if (['black', 'canceled', 'cancelled', 'owed', 'deleted'].includes(pillType)) {
    return 'cancelled';
  } else if (['tag', 'tagging'].includes(pillType)) {
    return 'tag';
  } else if (['applied-filter'].includes(pillType)) {
    return 'applied-filter';
  } else if (['red', 'failed', 'error', 'invalid', 'open'].includes(pillType)) {
    return 'error';
  } else {
    return 'standard';
  }
}

// TODO replace trim prop with no-wrap / text overflow css and handle it there

const DopePill = ({ leftIcon, rightIcon, text, pillType, showCircle = true, hoverable = false, url, trim, size, onClick, removeable = false, dataKey, pillLabelMap = {}, message = null }) => {
  const pillClassKey = (pillType || text).toLowerCase();
  const pillClass = pillClassSetter(pillClassKey);
  const pillCircleSize = size ? `pill-circle-${size}` : '';
  const pillSize = size ? `dope-pill-${size}` : '';
  const pillTextSize = size ? `pill-text-${size}` : '';

  let displayText;
  if (pillLabelMap[text]) {
    displayText = pillLabelMap[text];
  } else {
    displayText = trim && text.length > trim ? humanize(text).slice(0, trim) + "..." : humanize(text);
  }

  if (pillType === 'applied-filter') {
    leftIcon = <Icon as={MdCheck} />;
  }

  if (removeable) {
    rightIcon = <Icon as={CloseIcon} />;
  }

  const clickableClass = onClick ? 'clickable' : '';
  const hoverableClass = hoverable ? 'hoverable' : '';

  const pill = (
    <div className={`dope-pill ${pillClass} ${pillSize} ${clickableClass} ${hoverableClass}`} onClick={onClick} >
      {url ?
        <Link to={url} style={{ color: '#494B4E', cursor: 'pointer' }}>{leftIcon} {displayText}</Link> :
        <div className="dope-pill-content">
          {showCircle && <div className={`pill-circle ${pillClass} ${pillCircleSize}`}></div>}
          <div className={`pill-text ${pillTextSize}`}>{leftIcon} {displayText} {rightIcon}</div>
        </div>
      }
    </div>
  );

  if (!message) {
    return pill;
  }

  return (
    <DopeToolTip content={message}>
      {pill}
    </DopeToolTip>
  );
}

export default DopePill;
