import React, { useEffect, useState } from "react";

import { useNavigate, useLocation } from 'react-router-dom';

import { useQuery2 } from "../hooks/useQuery";
import { deserializeParams } from "../services/dopeClient";
import { formatToLocaleDateString } from "../utils/date";
import DopeApi from "../services/DopeApi";

import DopeSelectDrawer from "../ui/DopeSelectDrawer";
import DopeButton from "../ui/DopeButton";
import DopeListPage from "../ui/DopeListPage";
import { handleFilterChange } from "../utils/fn";
import authApi from "../auth/authApi";
import { Icon } from "@rsuite/icons";
import { FaPlus } from "react-icons/fa";
import CampaignTypeCell from "./CampaignTypeCell";
import CampaignDispatchesStatusCell from "./CampaignDispatchesStatusCell";
import CampaignStatusCell from "./CampaignStatusCell";

const campaignTypeOptions = [
  { label: "List Campaign", description: 'Upload a list or choose an existing one and send a campaign to each person on that list.', value: "list", icon: "list_campaign" },
  { label: "Neighborhood Blitz", description: 'Draw a custom shape on a map and send mail to residents or businesses within that shape.', value: "blitz", icon: "blitz" },
  { label: "List Blitz", description: 'Quickly send mail to 25, 50, or more neighbors around a list of addresses.', value: "list_blitz", icon: "list_blitz" },
  { label: "EDDM", description: "Every Door Direct Mail allows you to send a campaign to a mailing route in the neighborhood you want to target.", value: "eddm", icon: "eddm" },
];

const generationTypeOptions = [
  { label: "Automation", value: "AutomationRun" },
  { label: "Manual", value: "User" },
];

const campaignStatusOptions = [
  { label: "Draft", value: "draft" },
  { label: "Scheduled", value: "scheduled" },
  { label: "In Progress", value: "in_progress" },
  { label: "Complete", value: "complete" },
  { label: "Error", value: "generation_error" },
  { label: "Cancelled", value: "cancelled" },
];

const getUrl = ({ id, scheduled, is_eddm, audience_generation_type_key }) => {
  if (scheduled) {
    return `/campaigns/${id}/show`;
  }
  // TODO should probably have each type have its own details page and have a mast component determine which to render
  let detailsPath = is_eddm ? `/campaigns/${id}/eddm_routes` : `/campaigns/${id}/details`; // Campaign component will redirect to first invalid page
  if (audience_generation_type_key === "blitz") { // Blitz campaigns do not have a details page, they go straight to audience
    detailsPath = `/campaigns/${id}/audience`;
  }

  return detailsPath;
};

const getDisabled = (rowData) => {
  return rowData.status === "queued" || rowData.status === "generating" || rowData.name === "Historical Sendings"; // we dont let people view historical campaign as its too much data
};

const columns = [
  { type: 'custom_component', width: 44, dataKey: 'audience_generation_type', label: '', Component: CampaignTypeCell },
  { type: 'stacked_text', flexGrow: 2, resizable: true, dataKey: ['name', 'trigger_full_name'], secondaryText: 'Trigger:', label: 'Name', sortable: true, filterable: true, getDisabled, getUrl, customStyles: {color: "#292B2E"} },
  { type: 'date', width: 150, dataKey: 'created_at', label: 'Created Date', sortable: true, filterable: false, dateConverter: formatToLocaleDateString },
  { type: 'custom_component', flexGrow: 2, resizable: true, label: 'Mail Sendings', dataKey: 'dispatch_statuses', Component: CampaignDispatchesStatusCell },
  { type: 'custom_component', width: 180, label: 'Status', dataKey: 'effective_status', Component: CampaignStatusCell },
  { type: 'dropdown', dataKey: 'actions', dropDownType: 'campaign', width: 75, getDisabled, getUrl },
];

const api = new DopeApi('campaign');
const taggingsApi = new DopeApi('taggings');

const CampaignTable = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [campaignTypeSelectOpen, setCampaignTypeSelectOpen] = useState(false);

  const searchParams = deserializeParams(location.search);
  const initialScopes = searchParams.initialScopes || [];

  const {
    error,
    total,
    tableProps,
    paginationProps,
    filters,
    setFilters,
    scopes,
    setScopes,
    onSearchClear,
    onSearchInput,
  } = useQuery2({
    api,
    initialSearchScope: 'name'
  });

  useEffect(() => {
    if (location.state && location.state.openDrawer) {
      setCampaignTypeSelectOpen(true);
      location.state.openDrawer = false;
    }
  }, []);

  const handleClickNew = () => {
    setCampaignTypeSelectOpen(true);
  };

  const handleCreateNew = (campaignTypeSelection) => {
    setCampaignTypeSelectOpen(false);
    if (campaignTypeSelection === "eddm") {
      navigate(`/campaigns/new/eddm_routes?type=eddm`);
    } else if (campaignTypeSelection === "blitz") {
      navigate(`/campaigns/new/audience?type=blitz`);
    } else {
      navigate(`/campaigns/new/details?type=${campaignTypeSelection}`);
    }
  };

  const applyTagFilters = (tags) => {
    const filterObject = {
      filterField: "taggings.tag_id",
      operator: "in",
      filterCategory: "Tags"
    };
    handleFilterChange(filters, setFilters, tags, filterObject);
  };

  const applyGenerationTypeFilter = (generationType) => {
    const filterObject = {
      filterField: 'creator_type',
      operator: "in",
      filterCategory: "Trigger Type",
    };
    handleFilterChange(filters, setFilters, generationType, filterObject);
  };

  const filterInputs = [
    { title: "Status", type: "scopeArgs", options: campaignStatusOptions, name: "by_campaign_status", singleSelect: true },
    { title: "Campaign Type", type: "scopeArgs", options: campaignTypeOptions, name: "by_audience_generation_type_keys" },
    { title: "Trigger Type", getOptions: () => generationTypeOptions, applyFilter: applyGenerationTypeFilter },
    { title: "Tags",
      getOptions: (name = "") => taggingsApi.getOptions( {
        valueKey: "tag_id",
        labelKey: "tag.name",
        filters: [{ field: "taggable_type", operator: "=", value: "Campaign" }, { field: "tags.account_id", operator: "=", value: authApi.getCurrentAccountId() }],
        scopes: [{ name: "search_tag_by_name", args: name }]
      }),
      applyFilter: applyTagFilters,
      searchable: true
    },
  ];

  return (
    <div className="page">
      <div className="page-header">
        <p className="header-text">Campaigns</p>
        <p className="help-text">{total} Campaigns</p>
      </div>

      <div className="top-right-actions">
        <DopeButton
          icon={<Icon as={FaPlus} size={"10.5px"} style={{margin: "2px 4px 0 0"}} />}
          props={{
            label: "Create New",
            onClick: handleClickNew,
            buttonClass: "filled"
          }}
        />
      </div>

      <DopeListPage
        paginationProps={paginationProps}
        tableProps={tableProps}
        columns={columns}
        error={error}
        filterInputs={filterInputs}
        filters={filters}
        setFilters={setFilters}
        scopes={scopes}
        setScopes={setScopes}
        onSearchClear={onSearchClear}
        onSearchChange={onSearchInput}
        rowHeight={65}
      />

      <DopeSelectDrawer
        header="What type of campaign do you want to send?"
        open={campaignTypeSelectOpen}
        onCancel={() => setCampaignTypeSelectOpen(false)}
        onAction={handleCreateNew}
        options={campaignTypeOptions}
      />

    </div>
  );
};

export default CampaignTable;
