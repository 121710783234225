import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import pluralize from "pluralize";
import capitalize from "lodash/capitalize";

import { Divider, Drawer } from "rsuite";
import DopeTextInput from "../ui/DopeTextInput";
import DopeSelect from "../ui/DopeSelect";
import DopeAccordion from "../ui/DopeAccordion";
import AutomationPurchaseSummary from "./AutomationPurchaseSummary";
import AutomationDispatchTemplates from "./AutomationDispatchTemplates";
import SubscriptionSummary from "../integrations/Subscriptions/SubscriptionSummary";
import { useAutomation } from "./automationSlice";
import { DopeFormGroup } from "../ui/DopeForm";
import AdditionalCampaignOptions from "../campaigns/AdditionalCampaignOptions";
import DopeButton from "../ui/DopeButton";
import AutomationDetails from "./AutomationDetails";

const timePeriodOptions = [
  { value: 1, label: 'Day' },
  { value: 7, label: 'Week' },
  { value: 30, label: 'Month' },
  { value: 365, label: 'Year' },
];

const timeRangeOptions = [
  { value: 'day', label: 'Days' },
  { value: 'week', label: 'Weeks' },
  { value: 'month', label: 'Months' },
  { value: 'year', label: 'Years' },
];

const nameInput = { inputType: "text", name: "name", props: { label: "Automation Name" } };
const descriptionInput = { inputType: "text", name: "description", props: { label: "Description", as: "textarea", rows: 3 } };
const tagsInput = { inputType: "tags", name: "taggings", props: { label: "Tags", modelName: "automation" } };

const AutomationReview = () => {
  const [automationDetailsDrawerOpen, setAutomationDetailsDrawerOpen] = useState(false);
  const { automation, derivedAutomationAttrs, actions } = useAutomation();
  const { pageTabs } = useOutletContext();

  const settings = automation.campaign_template.list_generation_setting;
  const inputProps = { values: automation, onChange: actions.update, errors: automation.errors };
  const reviewSubheaders = {
    blitz: `closest ${settings.max_contacts_per_generation} people.`,
    list: "contact triggered."
  };

  const activateHeader = (
    <div className="page-header">
      <h2 className="header-2 margin-bottom">Let’s name your automation, review it and kick it off</h2>

      <div className="flex row vertical-align body-1">
        <div className="margin-4-r">
          Every time this automation is triggered, you will send mail to the {reviewSubheaders[settings.generation_type]}
        </div>
        <DopeButton
          className="text-link padding-none body-1"
          onClick={() => setAutomationDetailsDrawerOpen(true)}
        >See full details.</DopeButton>
      </div>
    </div>
  );

  const duplicateExpirationInput = (
    <div className="split-cols">
      <div>
        <div className="body-1 margin-4-b">Duplicate Trigger Expiration</div>
        <div className="help-text">
          By default we will filter out duplicate automation triggers. At what point would you like us to stop filtering them?
        </div>
      </div>

      <div className="vertical-align gap-10 to-right">
        <span>Expire after</span>
        <DopeTextInput
          type="number"
          name="duplicate_expiration_count"
          value={automation.duplicate_expiration_count}
          onChange={actions.update}
          style={{ width: 100 }}
          min={1}
        />
        <DopeSelect
          name="duplicate_expiration_period"
          value={automation.duplicate_expiration_period}
          onChange={actions.update}
          options={timeRangeOptions}
          style={{ width: 200 }}
          searchable={false}
          renderValue={(value) => capitalize(pluralize(value, automation.duplicate_expiration_count))}
        />
      </div>
    </div>
  );

  const throttleInput = (
    <div className="split-cols margin-bottom">
      <div className="">
        <div className="body-1 margin-4-b">Automation Throttle</div>
        <div className="help-text">
          Tell us the maximum amount of mail you’d like to send in any given time frame, and we’ll stop sending mail when we reach your threshold.
        </div>
      </div>

      <div className="vertical-align gap-10 to-right">
        <span>Maximum</span>
        <DopeTextInput
          type="number"
          name="throttle_count"
          value={automation.throttle_count}
          onChange={actions.update}
          style={{ width: 100 }}
          min={1}
        />
        <span>times per</span>
        <DopeSelect
          name="throttle_days"
          value={automation.throttle_days}
          onChange={actions.update}
          options={timePeriodOptions}
          style={{ width: 200 }}
          searchable={false}
        />
      </div>
    </div>
  );

  const triggerSettings = (
    <div className="margin-top">
      <DopeAccordion header="Automation Settings">
        <div style={{ padding: '10px 10px' }}>
          {throttleInput}

          {duplicateExpirationInput}
        </div>
      </DopeAccordion>
    </div>
  );

  return (
    <div>
      <div className="page">
        {pageTabs}
        {activateHeader}

        <div className="automation-form-group half-width">
          <DopeFormGroup input={nameInput} {...inputProps} className="margin-bottom" />
          <DopeFormGroup input={descriptionInput} {...inputProps} className="margin-bottom" />
          <AdditionalCampaignOptions
            header="Add tags to your campaign"
            panelBody={<DopeFormGroup input={tagsInput} {...inputProps} />}
          />
        </div>
      </div>

        <div className="page">
          {derivedAutomationAttrs.isNativeTrigger && (<SubscriptionSummary subscriptionId={automation.subscription_id}/>)}

          <div className="header-4 margin-tb">What's being sent</div>
          <AutomationDispatchTemplates
            withContactCol={true}
            withHeader={true}
            editable={false}
          />

          {triggerSettings}
        </div>

        <div className="page">
          <AutomationPurchaseSummary/>
        </div>

        <Drawer open={automationDetailsDrawerOpen} onClose={() => setAutomationDetailsDrawerOpen(false)} size="md">
          <Drawer.Body>
            <h3 className="header-3 margin-bottom">Automation Info</h3>
            <AutomationDetails />
          </Drawer.Body>
        </Drawer>
      </div>
  );
}

export default AutomationReview;
