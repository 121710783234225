import React from "react";

import { Dropdown, Checkbox } from "rsuite";
import { Icon } from "@rsuite/icons";
import DopeButton from "../DopeButton";
import { MdCheck, MdOutlineArrowDropDown } from "react-icons/md";

const dropdownItemStyles = {
  fontWeight: 600,
  fontSize: '14px',
  color: '#292B2E',
};

const DopeScopeArgsFilter = ({ config, scopes, setScopes}) => {
  const { title, options, name, placement = "bottomStart", singleSelect = false } = config;

  const scope = scopes.find(scope => scope.name === name) || {
    name,
    args: [],
  };

  const scopeArgs = scope.args;

  const isChecked = (option) => {
    return scopeArgs.includes(option.value);
  };

  const removeScope = () => {
    setScopes(scopes.filter(s => s.name !== name));
  };

  const handleCheck = (option) => {
    let nextScope;
    if (isChecked(option)) {
      nextScope = {
        ...scope,
        args: scopeArgs.filter(value => value !== option.value),
      };
      if (!nextScope.args.length) {
        removeScope();
        return;
      }
    } else {
      if (singleSelect) {
        nextScope = {
          ...scope,
          args: [option.value],
        };
      } else {
        nextScope = {
          ...scope,
          args: [...scopeArgs, option.value],
        };
      }
    }
    setScopes([...scopes.filter(s => s.name !== name), nextScope]);
  };

  const applyAllValues = () => {
    const nextScope = {
      ...scope,
      args: options.map(option => option.value),
    };
    setScopes([...scopes.filter(s => s.name !== name), nextScope]);
  };

  const selectedCount = scopeArgs.length;
  const active = selectedCount > 0;

  const titleEl = (
    <div>
      {active && <Icon as={MdCheck} style={{ marginRight: "6px" }}/>}
      {title}
      <span style={{ fontSize: "11px", fontWeight: 700, marginLeft: "6px" }}>{active && `+${selectedCount}`}</span>
      <Icon as={MdOutlineArrowDropDown} style={{ fontSize: "20px", color: "#494B4E", marginLeft: "6px" }} />
    </div>
  );

  return (
    <Dropdown
      title={titleEl}
      toggleAs={"div"}
      placement={placement}
      menuStyle={{ marginTop: "5px" }}
      className={`dope-filter-dropdown ${active ? "selected-filter" : ""}`}
    >
      <div className="pad">
        <div style={{ width: 300, maxHeight: 200, overflowY: 'auto', borderRadius: "8px", border: "1px solid #F3F3F3" }}>
          {options.map((option, index) => (
            <div key={index}>
              <Checkbox
                style={dropdownItemStyles}
                checked={isChecked(option)}
                onChange={() => handleCheck(option)}
              >
                <div style={dropdownItemStyles}>{option.label}</div>
              </Checkbox>
            </div>
          ))}
        </div>

        <div style={{marginTop: "10px"}} className="space-between row">
          {!singleSelect && <DopeButton
            props={{
              buttonClass: "filled-gray",
              label: "Select All",
              onClick: applyAllValues,
              styles: {
                fontSize: "14px",
                fontWeight: 500,
                padding: "4px 20px",
              }
            }}
          />}

          <DopeButton
            props={{
              buttonClass: "text-link",
              label: "Reset Filters",
              onClick: removeScope,
              styles: { fontSize: "14px", fontWeight: 600, padding: "4px", textDecoration: "none" },
            }}
          />
        </div>
      </div>
    </Dropdown>
  );
};

export default DopeScopeArgsFilter;
