import React from "react";

import { useAutomation } from "./automationSlice";

import Fix from "./AutomationRunActions/Fix";
import ActionResultLink from "./AutomationRunActions/ActionResultLink";
import { Approve, SendAnyways, UndoDecline } from "./AutomationRunActions/Approve";
import DopePill from "../ui/DopePill";
import { DopeToolTip } from "../ui/DopeToolTip";


const statusToActionText = {
  pending: 'processing...',
};

const statusToActionComponent = {
  complete: ActionResultLink,
  error: Fix,
  invalid: Fix,
  duplicate: SendAnyways,
  needs_approval: Approve,
  declined: UndoDecline,
  throttled: SendAnyways,
  pending: null,
};

const AutomationRunActionCell = ({ rowData, reload }) => {
  const { derivedAutomationAttrs } = useAutomation();

  if (rowData.historical_id) { // HISTORICAL PATCH
    return <DopePill text="Historical Trigger" pillType="tag" />;
  }

  const action = statusToActionText[rowData.status];
  const ActionComponent = statusToActionComponent[rowData.status];

  const content = ActionComponent ? <ActionComponent id={rowData.id} rowData={rowData} reload={reload} /> : action;

  const hasDispatches = derivedAutomationAttrs.noDispatches === true || (rowData.dispatch_templates instanceof Array && rowData.dispatch_templates.length > 0)

  if (hasDispatches || rowData.status === 'complete') {
    return content;
  }

  return (
    <DopeToolTip content="Add designs to your automation to update your triggers." placement="left">
      <div className="disabled">
        {content}
      </div>
    </DopeToolTip>
  );
}

export default AutomationRunActionCell;
