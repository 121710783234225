import React, { useEffect, useState } from "react";
import { Dropdown } from "rsuite";
import ArrowDownLineIcon from '@rsuite/icons/ArrowDownLine';

import StripeApi from "../stripe/StripeApi";
import authApi from "../auth/authApi";
import { useStripeUI } from "../stripe/stripeUISlice";
import { useDopeUI } from "../ui/dopeUISlice";
import DopeButton from "../ui/DopeButton";
import DopeCheckbox from "../ui/DopeCheckbox";
import { Icon } from "@rsuite/icons";
import { FaPlus } from "react-icons/fa";
import CreditCardLogo from "../ui/CreditCardLogo";
import "./Account.scss";

const stripeApi = new StripeApi;

const CustomDropdown = ({ removePaymentMethod, setDefaultPaymentMethod, isDefaultPaymentMethod }) => {
  return (
    <Dropdown className="navbar-dropdown-button" title={<ArrowDownLineIcon style={{fontSize: "22px"}}/>} trigger="click" noCaret>
      <Dropdown.Item onSelect={setDefaultPaymentMethod} disabled={isDefaultPaymentMethod}>Make Default Card</Dropdown.Item>
      <Dropdown.Item onSelect={removePaymentMethod} disabled={isDefaultPaymentMethod}>Remove Card</Dropdown.Item>
    </Dropdown>
  );
};

const PaymentMethodDisplay = ({ paymentMethod, setPaymentMethods, paymentMethods, defaultPaymentMethod, setDefaultState, selectable = true }) => {
  const [dopeUI, dopeUIActions] = useDopeUI();
  const isDefaultPaymentMethod = paymentMethod.id === defaultPaymentMethod;

  const removePaymentMethod = async () => {
    await stripeApi.post("remove_payment_method", { payment_method_id: paymentMethod.id });
    setPaymentMethods(paymentMethods.filter((pm) => pm.id !== paymentMethod.id));
    dopeUIActions.addFlashMessage({ header: "Payment Method Removed", body: "Your payment method has been removed.", type: "success" });
  }

  const setDefaultPaymentMethod = async () => {
    const payment_method_response = await stripeApi.post("set_default_payment_method", { payment_method_id: paymentMethod.id });
    setDefaultState(paymentMethod.id);
    const payment_status = payment_method_response.stripe_payment_method.payment_status;
    if (payment_status === "current") {
      dopeUIActions.addFlashMessage({ header: "Default Payment Method Updated", body: "Your default payment method has been updated.", type: "success" });
    } else {
      dopeUIActions.addFlashMessage({ header: "Payment Method Updated", body: "Your default payment method has been updated but your account has an outstanding balance. Please reach out to your account manager.", type: "error" });
    }
  }

  return (
    <div className="flex">
      <div className={`payment-method-wrapper ${selectable ? 'selectable' : ''}`}>
        <div className="vertical-align row">
          <div className="credit-card-logo-wrapper margin-8-r">
            <CreditCardLogo brand={paymentMethod.card.brand}/>
          </div>
          <div>
            <div
              className="label large bold margin-8-r">{paymentMethod.card.brand.toUpperCase()} **{paymentMethod.card.last4}</div>
            <div className="label non-bold optional margin-8-r">EXPIRY {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}</div>
          </div>
        </div>

        {selectable && <div className="vertical-align row">
          {isDefaultPaymentMethod && <div className="label optional color-text-secondary">DEFAULT</div>}

          <CustomDropdown
            paymentMethod={paymentMethod}
            removePaymentMethod={removePaymentMethod}
            paymentMethods={paymentMethods}
            setDefaultPaymentMethod={setDefaultPaymentMethod}
            isDefaultPaymentMethod={isDefaultPaymentMethod}
          />
        </div>}
      </div>
    </div>
  );
}

const AccountPaymentMethods = ({ cardReload }) => {
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [stripeUI, stripeUIActions] = useStripeUI();
  const [dopeUI, dopeUIActions] = useDopeUI();
  const currentAccount = authApi.getCurrentAccount();
  const [purchaseSetting, setPurchaseSetting] = useState(currentAccount.purchase_setting);
  const [updatingPurchaseSetting, setUpdatingPurchaseSetting] = useState(false);
  const isPayAsYouGo = purchaseSetting === "AS_YOU_GO";

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      const paymentMethods = await stripeApi.get("stripe_payment_methods");
      setPaymentMethods(paymentMethods.payment_methods);
      setDefaultPaymentMethod(paymentMethods.default_payment_method_id);
    }

    fetchPaymentMethods();
  }, [cardReload]);

  const handlePurchaseSettingChange = (update, checked) => {
    const purchase_setting = checked ? "AS_YOU_GO" : "UPFRONT";
    setUpdatingPurchaseSetting(true);
    authApi.updateAccount({ purchase_setting })
      .then(account => {
        setPurchaseSetting(account.purchase_setting);
        dopeUIActions.addFlashMessage({ header: "Payment Setting Updated", body: "Your purchase setting has been updated.", type: "success" });
      })
      .catch(error => {
        console.error(error);
        dopeUIActions.addFlashMessage({ header: "Error", body: "There was an error updating your payment setting.", type: "error" });
      })
      .finally(() => setUpdatingPurchaseSetting(false));
  };

  return (
    <div className="page-content-box split-cols">
      <div>
        <div className="header-6">Credit Card(s)</div>
        <div className="helpt-text margin-bottom">Update and change your default card here. Updating your account's default payment method will attempt to settle any outstanding balances on the latest subscription invoice.</div>

        {paymentMethods && paymentMethods.map((paymentMethod) => (
          <PaymentMethodDisplay
            paymentMethod={paymentMethod}
            paymentMethods={paymentMethods}
            setPaymentMethods={setPaymentMethods}
            key={paymentMethod.id}
            defaultPaymentMethod={defaultPaymentMethod}
            setDefaultState={setDefaultPaymentMethod}
          />
        ))}

        <DopeButton
          icon={<Icon as={FaPlus} size={"8px"} style={{margin: "0 4px 0 0"}} />}
          props={{
            onClick: () => stripeUIActions.setCardDrawerOpen(true),
            label: "Add Card",
            buttonClass: "outlined-black",
            styles: { height: "30px", fontSize: "11px", fontWeight: 700, marginTop: "10px" }
          }}
        />
      </div>

      <div>
        <div className="header-6">Payment Settings</div>
        <div className="helpt-text margin-bottom">
          Choose how you want to be charged for your mail.
          If you’d like to pay as each individual sending goes out, turn on the “Pay As You Go” feature below.
          If you’d like to pay for an entire campaign or an entire automation trigger when scheduled, turn off the “Pay As You Go” feature below.
        </div>

        <DopeCheckbox
          useToggle
          headerText="Pay As You Go"
          helpText="You will get individually billed on the day of each scheduled sending."
          onChange={handlePurchaseSettingChange}
          value={isPayAsYouGo}
          disabled={updatingPurchaseSetting}
        />
      </div>

    </div>
  );
}

AccountPaymentMethods.PaymentMethodDisplay = PaymentMethodDisplay;

export default AccountPaymentMethods;
