import React, { useState } from "react";
import { Accordion } from "rsuite";

import DopeAccordionHeader from "./DopeAccordionHeader";

const panelContainerStyles = {
  border: '1px solid #EAECEF',
  borderTop: 'none',
  padding: '10px 16px',
  borderRadius: '0 0 8px 8px',
};

const DopeAccordion = ({ header, children }) => {
  const [activeKey, setActiveKey] = useState(null);

  const handleAccordionChange = (key) => {
    setActiveKey(key === activeKey ? null : 1);
  };

  const accHeader = (
    <DopeAccordionHeader
      open={activeKey === 1}
      header={header}
      label={true}
      primary={true}
    />
  );

  return (
    <Accordion activeKey={activeKey} onSelect={handleAccordionChange} classPrefix="dope">
      <Accordion.Panel header={accHeader} eventKey={1} caretAs={() => <></>}>
        <div style={panelContainerStyles}>
          {children}
        </div>
      </Accordion.Panel>
    </Accordion>
  );
};

export default DopeAccordion;
