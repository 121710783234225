import React, { useState } from "react";
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { useQuery2 } from "../hooks/useQuery";
import DopeApi from "../services/DopeApi";
import DopeListPage from "../ui/DopeListPage";
import DopeDrawer from "../ui/DopeDrawer";
import AgencyDrawer from "../agencies/AgencyDrawer";
import DopeConfirmationModal from "../ui/DopeConfirmationModal";
import { useAgency } from "../agencies/agencySlice";

import Cents from "../ui/Cents";
import { formatDateStringToLocaleDateString } from "../utils/date";
import { FiDownload } from "react-icons/fi";

const columns = [
  { type: "stacked_text", dataKey: ['name', 'account_email'], label: "Name", flexGrow: 3, sortable: true, },

  { type: "text", dataKey: 'kickback.client_count', label: "Clients", flexGrow: 2, sortable: true, placeholder: '0' },
  { type: "cents", dataKey: 'kickback.spent', label: "Spend this month", flexGrow: 2, sortable: true, },
  { type: "text", dataKey: 'kickback.mail_count', label: "Mail This Month", flexGrow: 2, sortable: true, placeholder: '0' },
  { type: "text", dataKey: 'kickback.last_period.mail_count', label: "Mail Last Month", flexGrow: 2, sortable: false, placeholder: '0' },
  { type: "custom", dataKey: 'kickback.amount', label: "Kickback", flexGrow: 2, sortable: true, render: (row) => {
    return <div style={{display: 'flex', flexDirection: 'column', gap: '0.5rem'}}>
      <Cents>{row.kickback?.amount || 0}</Cents>
      {row.kickback?.next_period_start_date && <p style={{color: '#8793A6'}}><small><Cents>{row.kickback?.next_period_amount || 0}</Cents> on {formatDateStringToLocaleDateString(row.kickback?.next_period_start_date)}</small></p>}
    </div>;
  }},
];

const accountsApi = new DopeApi('agencies');
const kickbackApi = new DopeApi('kickback');

const PulseAgenciesTable = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams();
  const { agency } = useAgency();

  const [kickback, setKickback] = useState(null);
  const [showKickbackUpdate, setShowKickbackUpdate] = useState(false);

  const { error, tableProps, paginationProps, onSearchInput, onSearchClear, setFilters, filters, scopes, setScopes, reload, exportCSV, exporting }
    = useQuery2({
      api: accountsApi,
    });


  const actionButtons = [
    {
      label: 'Export',
      icon: <FiDownload />,
      onClick: () => exportCSV('agencies'),
      isLoading: () => exporting,
      isDisabled: () => exporting,
    }
  ]


  const onRowClick = (row) => {
    navigate(`/pulse/agencies/${row.id}`);
  };

  return (
    <div className="page">

      <div className="page-header">
        <p className="header-text">Agency Accounts</p>
      </div>

      <DopeListPage
        columns={columns}
        tableProps={tableProps}
        paginationProps={paginationProps}
        error={error}
        filterInputs={[]}
        onSearchChange={onSearchInput}
        onSearchClear={onSearchClear}
        setFilters={setFilters}
        rowHeight={100}
        onRowClick={onRowClick}
        filterActionButtons={actionButtons}
      />

      {id && <DopeDrawer
        open={!!id}
        onClose={() => navigate('/pulse/agencies')}
        size="md"
      >
        <AgencyDrawer
          id={id}
          setShowKickbackUpdate={setShowKickbackUpdate}
          setKickback={setKickback}
          kickback={kickback}

          />

      </DopeDrawer>}

      {agency && <DopeConfirmationModal
        open={showKickbackUpdate}
        size="sm"
        handleClose={() => setShowKickbackUpdate(false)}
        onConfirm={() => {
          const { id, amount } = kickback;

          const inCents = parseFloat(amount) * 100;

          kickbackApi.update({ id, amount: inCents })
            .then(() => {
              setShowKickbackUpdate(false);

              reload();
              navigate('/pulse/agencies');
            });
        }}
        onCancel={() => setShowKickbackUpdate(false)}
        title={<p style={{whiteSpace: 'break-spaces'}}>Are you sure you want to update {agency.name}'s Kickback amount?</p>}
        message={'This kickback will be updated on the first of the month and will update reporting to that amount moving forward'}
        confirmLabel="Yes, Update Kickback"
        confirmButtonClass="filled-black"
        cancelLabel="No, Cancel"
        cancelButtonClass="outlined-black"
      />}

    </div>
  );
};

export default PulseAgenciesTable;
