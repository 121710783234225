import React, { useState } from 'react';
import { Link } from "react-router-dom";
import TrashIcon from "@rsuite/icons/Trash";
import EditIcon from "@rsuite/icons/Edit";

import DopeApi from "../services/DopeApi";
import DopeConfirmationModal from "../ui/DopeConfirmationModal";
import { useDopeUI } from "../ui/dopeUISlice";

const automationsApi = new DopeApi('automation');

const iconStyles = {
  marginRight: "16px",
  color: "#292B2E",
  fontSize: "18px",
};


const AutomationDropdown = ({ rowData: automation, reloadTable, getUrl }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [dopeUI, dopeUIActions] = useDopeUI();

  const handleClickDelete = async () => {
    setIsDeleting(true);
    try {
      await automationsApi.destroy(automation.id);
      await reloadTable();
    } catch (error) {
      dopeUIActions.addFlashMessage({ type: "error", header: "Failed to delete automation", body: "Only draft automations can be removed" });
    } finally {
      setIsDeleting(false);
    }
  };

  const url = getUrl(automation);

  const isDraft = automation.status === "draft";
  const viewLabel = isDraft ? "Edit Automation" : "View Automation";

  const discardButton = isDraft && (
    <div onClick={() => setShowConfirmDelete(true)} className="vertical-align row clickable text margin-16-b label large bold"><TrashIcon style={iconStyles} /> Discard Draft</div>
  );

  return (
    <>
      <Link to={url} className={`vertical-align row clickable margin-8-tb label large bold`}><EditIcon style={iconStyles} /> {viewLabel}</Link>
      {discardButton}

      <DopeConfirmationModal
        open={showConfirmDelete}
        title={`Are you sure you want to delete ${automation.name} automation?`}
        message="Once deleted we will not be able to restore anything that was already built."
        onConfirm={handleClickDelete}
        onCancel={() => setShowConfirmDelete(false)}
        confirmLabel="Yes, delete"
        cancelLabel="No, cancel"
        confirmButtonClass="filled-black"
        cancelButtonClass="outlined-black"
        loading={isDeleting}
      />
    </>
  );
}

export default AutomationDropdown;
