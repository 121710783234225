import React from "react";
import { useOutletContext } from "react-router-dom";
import pluralize from "pluralize";
import capitalize from "lodash/capitalize";

import { useAutomation } from "./automationSlice";

import DopePageStepHeader from "../ui/DopePageStepHeader";
import DopeSelect from "../ui/DopeSelect";
import DopeTextInput from "../ui/DopeTextInput";
import { DopeFormGroup } from "../ui/DopeForm";
import AutomationDispatchTemplates from "./AutomationDispatchTemplates";
import AudienceFiltersInput from "../campaigns/AudienceFiltersInput";
import CampaignListsSelect from "../campaigns/CampaignListsSelect";
import { FiltersSummary } from "../campaigns/CampaignFiltersSummary";
import { Radio, RadioGroup } from "rsuite";
import AdditionalCampaignOptions from "../campaigns/AdditionalCampaignOptions";

const audienceTypeOptions = [
  { value: 'list', label: 'Direct Sending', helpText: '1 card to 1 address', icon: 'mail' },
  { value: 'blitz', label: 'Neighborhood Blitz', helpText: 'Mail to X number of neighbors of your clients', icon: 'house' },
];

const timeRangeOptions = [
  { value: 'day', label: 'Days' },
  { value: 'week', label: 'Weeks' },
  { value: 'month', label: 'Months' },
  { value: 'year', label: 'Years' },
];

const suppressPreviousSendsInput = {
  inputType: "checkbox",
  name: "suppress_previous_sends",
  props: {
    headerText: "I want to filter out anyone I’ve already sent to:",
    size: "sm",
  },
};

const metersPerMile = 1609; // max distance options should be a shared constant

const maxDistanceOptions = [
  { label: "3 miles", value: 3 * metersPerMile },
  { label: "5 miles", value: 5 * metersPerMile },
  { label: "10 miles", value: 10 * metersPerMile },
  { label: "25 miles", value: 25 * metersPerMile },
  { label: "50 miles", value: 50 * metersPerMile },
  { label: "100 miles", value: 100 * metersPerMile },
];

const inputStyle = { width: 150 };

const AutomationCampaignTemplate = () => {
  const { automation, actions } = useAutomation();
  const { pageTabs } = useOutletContext();

  const listGenerationSetting = automation.campaign_template.list_generation_setting;

  const isBlitz = listGenerationSetting.generation_type === 'blitz';

  const blitzSettings = isBlitz && (
    <div className="radio-button-margin-offset">
      <div className="body-1 bold margin-top margin-4-b">How many neighbors do you want to send to?</div>
      <div className="vertical-align gap-10">
        <DopeTextInput
          name="max_contacts_per_generation"
          value={listGenerationSetting.max_contacts_per_generation}
          onChange={actions.updateListGenerationSetting}
          type="number"
          style={inputStyle}
        />
      </div>

      <div className="body-1 bold margin-top margin-4-b">At what distance should we should cap our search?</div>
      <div className="vertical-align gap-10">
        <DopeSelect
          name="max_distance"
          value={listGenerationSetting.max_distance}
          onChange={actions.updateListGenerationSetting}
          options={maxDistanceOptions}
          style={inputStyle}
          searchable={false}
        />
      </div>

      <div className="body-1 bold margin-top">Do you want to filter out certain neighbors?</div>
      <AudienceFiltersInput
        onUpdate={actions.updateDataAxleFilters}
        onRemove={actions.removeDataAxleFilter}
        values={listGenerationSetting}
        buttonClass="outlined-black float-none margin-4-t margin-4-b"
      />
      <div>
        <FiltersSummary
          filters={listGenerationSetting.data_axle_filters}
          onRemove={actions.removeDataAxleFilter}
        />
      </div>
    </div>
  );

  return (
    <div>
      <div className="page">
        {pageTabs}
        <div className="page-header">
          <p className="header-text">Define what happens after your trigger</p>
          <p className="body-1 color-text-secondary">Select what we should send, and to who, after your trigger is fired.</p>
        </div>

        <DopePageStepHeader
          step="1"
          headerText="Add Mailers to your campaign"
        />

        <AutomationDispatchTemplates />
      </div>

      <div className="page">
        <DopePageStepHeader
          step="2"
          headerText="What type of automation are we creating, who should your campaign go to?"
        />

        <RadioGroup value={listGenerationSetting.generation_type} onChange={value => actions.updateListGenerationSetting({ generation_type: value })} >
          {audienceTypeOptions.map((option) => (
            <Radio value={option.value} key={option.value} >
              <div className="header-6 line-height-1 margin-4-b">{option.label}</div>
              <div className="body-1 color-text-secondary">{option.helpText}</div>
            </Radio>
          ))}
        </RadioGroup>

        {blitzSettings}

        <AdditionalCampaignOptions
          additionalRulesApplied={listGenerationSetting.add_list_generation_setting_suppression_lists || listGenerationSetting.suppress_previous_sends}
          settings={listGenerationSetting}
          panelBody={(<div>
            <DopeFormGroup
              input={suppressPreviousSendsInput}
              values={listGenerationSetting}
              onChange={actions.updateListGenerationSetting}
            />

            {
              listGenerationSetting.suppress_previous_sends && (
                <div className="vertical-align gap-10 margin-40-l">
                  <span>in the last</span>
                  <DopeTextInput
                    type="number"
                    name="suppress_previous_expiration_count"
                    value={listGenerationSetting.suppress_previous_expiration_count}
                    onChange={actions.updateListGenerationSetting}
                    style={{ width: 100 }}
                    min={1}
                  />
                  <DopeSelect
                    name="suppress_previous_expiration_period"
                    value={listGenerationSetting.suppress_previous_expiration_period}
                    onChange={actions.updateListGenerationSetting}
                    options={timeRangeOptions}
                    style={{ width: 200 }}
                    searchable={false}
                    renderValue={(value) => capitalize(pluralize(value, listGenerationSetting.suppress_previous_expiration_count))}
                  />
                </div>
              )
            }

            <CampaignListsSelect
              headerText="I want to filter out the contacts from the following lists:"
              name="list_generation_setting_suppression_lists"
              values={listGenerationSetting}
              onChange={actions.updateListGenerationSetting}
            />
          </div>)}
        />

      </div>
    </div>
  );
};

export default AutomationCampaignTemplate;
