import React, {useEffect, useState} from "react";
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { Icon } from '@rsuite/icons';
import { FaPlus } from "react-icons/fa";
import { FaGears } from "react-icons/fa6";

import DopeTabs from "../ui/DopeTabs";
import DopeSelectDrawer from "../ui/DopeSelectDrawer";
import DopeApi from "../services/DopeApi";

import AutomationTable from "./AutomationTable";
import AllAutomationRunsTable from "./AllAutomationRunsTable";
import DopeButton from "../ui/DopeButton";
import authApi from "../auth/authApi";
import CannotInfoBox from "../ui/CannotInfoBox";
import { useAutomation } from "./automationSlice";
import { errorsToErrorList } from "../utils/errors";
import { useDopeUI } from "../ui/dopeUISlice";

const accountApi = new DopeApi('account');

const automationTypeOptions = [
  { label: "Send Mail", value: "CampaignTemplate", icon: "mail", description: "Upload a list or choose an existing one and send a campaign to each person on that list." },
  { label: "Create a New Home Buyers feed", disabled: true, value: "NewHomeBuyersTemplate", icon: "add_house", description: "Coming soon!" },
  { label: "Create List", disabled: true, value: "UpdateListTemplate", icon: "list", description: "Coming soon!" },
  // { label: "Setup a Setting Notification", disabled: true, value: "NotifyTemplate", icon: "notify", description: "Coming soon!" },
  // { label: "Generate a Report", disabled: true, value: "ReportTemplate", icon: "report", description: "Coming soon!" },
];



const AutomationsAndRuns = () => {
  const [automationTypeSelectOpen, setAutomationTypeSelectOpen] = useState(false);
  const [automationLimitReached, setAutomationLimitReached] = useState(false);
  const [accountAutomationLimitText, setAccountAutomationLimitText] = useState("");
  const { automation, actions } = useAutomation();
  const [dopeUI, dopeUIActions] = useDopeUI();

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  useEffect(() => {
    const checkAccountAutomationLimit = async () => {
      const currentAccountId = await accountApi.get(authApi.getCurrentAccountId());
       return await accountApi.fetchMember(currentAccountId, 'has_reached_automation_limit');
    }

    checkAccountAutomationLimit().then((result) => {
      setAutomationLimitReached(result.limit_reached);
      if (result.automation_allotment > 0) {
        setAccountAutomationLimitText("You've hit the max number of automations your subscription allows. Pause an automation or get in touch with us to upgrade.");
      } else {
        setAccountAutomationLimitText("Automations are not included with your subscription - in order to setup an automation you'll need to upgrade to a paid subscription. Reach out to support@dopemarketing to get setup");
      }
    });
  }, []);

  const save = async (automationType) => {
    return actions.save({ action_template_type: automationType })
    .then(action => {
      const success = action.meta.requestStatus === 'fulfilled';
      if (success) {
        return Promise.resolve(action.payload);
      }

      return Promise.reject(action.payload);
    });
  };

  const handleClickNew = () => {
    setAutomationTypeSelectOpen(true);
    actions.get('new');
  };

  const navigateSetup = (automation) => navigate(`/automations/${automation.id}/trigger?type=${automation.action_template_type}`);

  const handleCreateNew = (automationType) => {
    setAutomationTypeSelectOpen(false);
    save(automationType).then(navigateSetup).catch((errors) =>
      dopeUIActions.addFlashMessage({
        type: 'error',
        header: 'Automation could not be created',
        body: errorsToErrorList(errors),
        timeout: 10000,
      })
    );
  };

  const defaultTab = () => {
    if (location.state?.tab) {
      return location.state.tab;
    } else if (tab) {
      return tab;
    } else {
      return 'automations';
    }
  }

  const automationLimitInfoBox =  (
    <div>
      {automationLimitReached && <CannotInfoBox
        classes="rs-drawer-info-box"
        icon={FaGears}
        body={accountAutomationLimitText}
      />
      }
    </div>
  )

  return (
    <div className="page">

      <div className="page-header">
        <p className="header-text">Automations & Triggers</p>
      </div>

      <div className="top-right-actions">
        <DopeButton
          icon={<Icon as={FaPlus} size={"10.5px"} style={{margin: "2px 4px 0 0"}} /> }
          props={{
            buttonClass: "filled",
            onClick: handleClickNew,
            label: "Create New",
          }}
        />
      </div>

      <DopeTabs defaultTab={defaultTab}>
        <DopeTabs.Tab
          key={'automations'}
          name='automations'
          label={'Automations'}
          disabled={false}
        >
          <AutomationTable automationLimitInfoBox={automationLimitInfoBox} />
        </DopeTabs.Tab>

        <DopeTabs.Tab
          key="automation_runs"
          name="automation_runs"
          label={'Triggers'}
          disabled={false}
        >
          <AllAutomationRunsTable />
        </DopeTabs.Tab>
      </DopeTabs>

      <DopeSelectDrawer
        header="What type of automation do you want to create?"
        infoBox={automationLimitInfoBox}
        open={automationTypeSelectOpen}
        onCancel={() => setAutomationTypeSelectOpen(false)}
        onAction={handleCreateNew}
        options={automationTypeOptions}
      />
    </div>
  );
};

export default AutomationsAndRuns;
