import React from 'react';
import { Popover, Dropdown } from 'rsuite';
import './DopeDropdown.scss';

import ContactDropdown from "../contacts/ContactDropdown";
import ContactListDropdown from "../contacts/ContactListDropdown";
import ListDropdown from "../lists/ListDropdown";
import QRCodeDropdown from "../analysis/QRCodeDropdown";
import TrackingNumberDropdown from '../analysis/TrackingNumberDropdown';
import EddmOrderDropdown from '../eddm/EddmOrderDropdown';
import SweetSpotAnalysisDropdown from '../analysis/SweetSpotAnalysisDropdown';
import HotLeadDropdown from '../analysis/HotLeadDropdown';
import CampaignDropdown from '../campaigns/CampaignDropdown';
import AutomationDropdown from '../automations/AutomationDropdown';


// TODO refactor, jamming toooo many things together, this needs to be part of table refactoring
const DropdownContent = ({ dropDownType, rowData, reloadTable, getUrl, getDisabled }) => {
  switch (dropDownType) {
    case 'contact':
      return <ContactDropdown rowData={rowData} />;
    case 'list':
      return <ListDropdown rowData={rowData} />;
    case 'contact_list':
      return <ContactListDropdown rowData={rowData} reloadTable={reloadTable} />;
    case 'qr_code':
      return <QRCodeDropdown rowData={rowData} />;
    case 'tracking_number':
      return <TrackingNumberDropdown rowData={rowData} />
    case 'eddm_order':
      return <EddmOrderDropdown rowData={rowData} reloadTable={reloadTable} />;
    case 'hot_lead':
      return <HotLeadDropdown rowData={rowData} />;
    case 'sweet_spot_analysis':
      return <SweetSpotAnalysisDropdown rowData={rowData} />;
    case 'campaign':
      return <CampaignDropdown rowData={rowData} reloadTable={reloadTable} getUrl={getUrl} getDisabled={getDisabled} />;
    case 'automation':
      return <AutomationDropdown rowData={rowData} reloadTable={reloadTable} getUrl={getUrl} />;
    default:
      return null;
  }
};

const DopeDropdown = ({ dropDownType, rowData, toggleWhisper, style, reloadTable, ...rest }, ref) => {

  return (
    <Popover ref={ref} className={`dope-dropdown ${dropDownType}`}  full arrow={false}>
      <Dropdown.Menu>
        <DropdownContent
          rowData={rowData}
          reloadTable={reloadTable}
          dropDownType={dropDownType}
          {...rest}
        />
      </Dropdown.Menu>
    </Popover>
  );
};

export default DopeDropdown;
