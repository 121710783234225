import React, {useEffect, useState} from "react";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { Message } from "rsuite";

import authApi from "../auth/authApi";
import StripeApi from "../stripe/StripeApi";
import StripeCardAdder from "../stripe/StripeCardAdder";
import SubscriptionSelectDropdown from "./SubscriptionSelectDropdown";
import { useDopeUI } from "../ui/dopeUISlice";
import { useAccount } from "../accounts/accountSlice";
import { useNavigate } from "react-router-dom";
import { Checkbox, Input } from "rsuite";
import DopeLogo from "../icons/DopeLogo";
import DopeSignaturePad from "../ui/DopeSignaturePad";
import DopeButton from "../ui/DopeButton";
import CouponApplier from "./CouponApplier";

const stripeApi = new StripeApi;

const SubscriptionFormStyles = {
  width: "90%",
  height: "auto",
  padding: "24px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  boxShadow: "0px 6px 6px 0px #BABABA40",
  display: "flex",
  flexDirection: "column",
  marginBottom: "24px"
}

function dataURLtoBlob(dataURL) {
  const arr = dataURL.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

const signatureUpload = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

const SubAccountMessage = () => {
  const navigate = useNavigate();

  const handleClickSkip = () => {
    navigate("/overview");
  };

  return (
    <div className="border text-center pad">
      <h5 className="header-5">You're on an agency account!</h5>
      <div className="help-text">
        That means you have custom pricing through us. Reach out to your Account Manager to review or change your subscription.
      </div>
      <div className="link-label to-right margin-top" onClick={handleClickSkip}>Skip Setup</div>
    </div>
  );
}

const SubscriptionForm = ({ clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [dopeUI, dopeUIActions] = useDopeUI();

  const [subscriptionOptions, setSubscriptionOptions] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [selectedSubscriptionName, setSelectedSubscriptionName] = useState('DOPE');
  const [userSignature, setUserSignature] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [signatureBlob, setSignatureBlob] = useState(null);
  const [account, accountActions] = useAccount();
  const [paymentError, setPaymentError] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const createSubscription = async (paymentMethod, signatureForRails) => {
    try {
      await accountActions.purchaseSubscription(selectedSubscription, paymentMethod, signatureForRails);
      await authApi.fetchMe();
      navigate("/overview");
    } catch (error) {
      dopeUIActions.addFlashMessage({ header: "Error", body: error.message, type: "error" });
    } finally {
      setLoading(false);
    }
  }

  const addPaymentMethod = async (paymentMethod) => {
    try {
      await accountActions.addPaymentMethod(paymentMethod);
      navigate("/overview");
    } catch (error) {
      dopeUIActions.addFlashMessage({ header: "Error", body: error.message, type: "error" });
    }
  }

  useEffect(() => {
    const fetchSubscriptions = async () => {
      // Need to initialize here, sometimes the authApi is not ready on first render
      try {
        const subscriptions = await stripeApi.get("stripe_subscription_data");
        setSubscriptionOptions(subscriptions.stripe_subscription_data.stripe_subscription_options
        .map(subscription => { return {...subscription, label: subscription.nickname, value: subscription.id}})
        );
      } catch (error) {
        console.log(error);
      }
    }

    if (!account) {
      accountActions.get(authApi.getCurrentAccountId());
    }

    fetchSubscriptions();
  }, []);

  useEffect(() => {
    if (subscriptionOptions.length > 0) {
      setSelectedSubscriptionName(subscriptionOptions.find(option => option.id === selectedSubscription).nickname)
    }
  }, [selectedSubscription]);

  const handleSelectChange = (value, event) => {
    setSelectedSubscription(value);
  };

  const formFilledOut = signatureBlob && acceptedTerms && userSignature;
  const isAgencyClient = account && account.account_type === "agency_client";

  const handleSubmit = async (event) => {
    event.preventDefault();
    await setLoading(true);

    if (!selectedSubscription && !isAgencyClient) {
      dopeUIActions.addFlashMessage({ header: "No plan selected", body: "Please select a plan before continuing", type: "error" });
      setLoading(false);
      return;
    } else if (!signatureBlob) {
      dopeUIActions.addFlashMessage({ header: "No signature", body: "Please sign your name before continuing", type: "error" });
      setLoading(false);
      return;
    } else if (!acceptedTerms) {
      dopeUIActions.addFlashMessage({ header: "Terms not accepted", body: "Please accept the terms before continuing", type: "error" });
      setLoading(false);
      return;
    } else if (account.subscription_promo_code && (!account.subscription_promo_code_data || !account.subscription_promo_code_data.verified)) {
      const stripeData = await stripeApi.post("verify_stripe_promo_code", { promo_code: account.subscription_promo_code });
      if (stripeData.error) {
        accountActions.update({ subscription_promo_code_data: { verified: false, error: stripeData.error } });
        setLoading(false);
        return;
      } else {
        accountActions.updatePromoCode(stripeData.promo_code);
      }
    }

    if (!stripe || !elements) return;

    const { paymentMethod, error } = await stripe.confirmSetup( {
      elements,
      redirect: 'if_required'
    });

    if (error) {
      setPaymentError(error);
      setLoading(false);
    } else {
      stripe.retrieveSetupIntent(clientSecret).then(async (result) => {
        if (result.setupIntent.status === "succeeded") {
          const signatureForRails = await signatureUpload(signatureBlob);
          if (isAgencyClient) {
            await addPaymentMethod(result.setupIntent.payment_method);
          } else {
            await createSubscription(result.setupIntent.payment_method, JSON.stringify(signatureForRails));
          }
        }
      })
    }
  };

  const handleUserSignatureInput = (value) => {
    setUserSignature(value);
  };

  const handleLogout = async () => {
    await authApi.logout();
  };

  const handleTermsCheck = (value) => {
    setAcceptedTerms(value);
  };

  const handleSignatureInput = (trimCallback) => {
    const trimmedURL = trimCallback()
    setSignatureBlob(dataURLtoBlob(trimmedURL));
  };

  if (!account) {
    return null;
  }

  return (
    <div className="vertical-align column">
      <DopeLogo style={{ width: "169px", height: "73px", margin: "20px 0" }}/>
      {subscriptionOptions.length > 0 && <div style={SubscriptionFormStyles}>
        <div className="flex row margin-bottom">
          <h3 className="margin-4-r">Payment & Terms </h3>
          <h3 className="color-text-secondary">{account.name}</h3>
        </div>
        <div className="flex row">
          <div className="full-width pad-lr-10">
            <div>
              {isAgencyClient ?
                (<SubAccountMessage />) : (
                  <SubscriptionSelectDropdown
                    subscriptionOptions={subscriptionOptions}
                    handleSelectChange={handleSelectChange}
                    selectedSubscription={selectedSubscription}
              />)}
            </div>

            <div className="margin-8-b">
              <CouponApplier />
            </div>

            <div className="margin-40-b">
              <h5 className="subscription-subheader">Your Billing Card</h5>
              <div className="help-text margin-4-b">We’ll use this card to bill for your subscription plan, postcards, handwritten cards, goodies, and more.</div>
              <StripeCardAdder clientSecret={clientSecret}/>
            </div>
            <div className="link-label" onClick={handleLogout}>
              Already have a plan? Logout
            </div>
          </div>
          <div className="pad-lr-10">
            <div className="margin-20-b">
              <h5 className="subscription-subheader margin-4-b">Review and sign our terms of service</h5>
              <div className="info-box">
                <div className="label large color-text-secondary margin-8-b">You are signing
                  up for a { selectedSubscriptionName } plan. You will be charged for the selected subscription. All mailers are additional
                  charges billed individually.
                </div>
                <a href="https://www.dopemarketing.com/terms-of-use" target="_blank" className="label large bold color-text-info">View Full Terms &
                  Conditions</a>
              </div>
            </div>
            <div className="signature-box pad-lr-10 pad-tb-10">
              <h5 className="subscription-subheader">Signature</h5>
              <label className="label">Full Name</label>
              <Input
                value={userSignature}
                onChange={(value) => handleUserSignatureInput(value)}
                style={{marginBottom: 5}}
              />
              <DopeSignaturePad handleSignatureInput={handleSignatureInput}/>
              <div className="flex row justify-start label large color-text-secondary">
                <Checkbox onChange={(value, checked) => handleTermsCheck(checked)}>
                  By signing this document with an electronic signature, I agree to the above terms and acknowledge that
                  this signature will be as valid as handwritten signatures to the extent allowed by local law.
                </Checkbox>
              </div>
            </div>

            {paymentError &&
              <Message showIcon type="error" header={paymentError.message} className="margin-16-t">
              </Message>
            }

            <DopeButton
              loading={loading}
              disabled={!formFilledOut}
              props={{
                onClick: handleSubmit,
                styles: {marginTop: "24px", width: "100%"},
                label: "Set Up My Account",
                buttonClass: "filled"
              }}
            />

          </div>
        </div>
      </div>}
    </div>
  );
}

export default SubscriptionForm;
