import React from "react";
import DopeCheckboxFilterDropdown from "./DopeCheckboxFilterDropdown";
import DopeDateRangeFilter from "./DopeDateRangeFilter";
import DopeScopeArgsFilter from "./DopeScopeArgsFilter";

const DopeFilterDelegator = ({ filterInput, filters, scopes, setScopes }) => {
  switch (filterInput.type) {
    case "dateRange":
      return <DopeDateRangeFilter filterInput={filterInput} filters={filters} />;
    case "checkbox":
      return <DopeCheckboxFilterDropdown filterInput={filterInput} filters={filters} />;
    case "scopeArgs":
      return <DopeScopeArgsFilter config={filterInput} scopes={scopes} setScopes={setScopes} />;
    default:
      return <DopeCheckboxFilterDropdown filterInput={filterInput} filters={filters} />;
  }
};

export default DopeFilterDelegator;
