import React from "react";
import { useCampaignListGenerationSettings } from "./campaignSlice";
import authApi from "../auth/authApi";
import { DopeFormGroup } from "../ui/DopeForm";
import CampaignListsSelect from "./CampaignListsSelect";
import { FaInfoCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

const ExtraMailRules = () => {
  const [settings, actions] = useCampaignListGenerationSettings();
  const { generation_type } = settings;
  const isListCampaign = generation_type === "list";
  const isBlitzCampaign = generation_type === "blitz";

  let suppressStartingInput = null;
  if (!isListCampaign) {
    const slideoverButton = (
      <Link
        to="#what-is-a-suppression-list"
        style={{ textDecoration: 'none', color: 'inherit' }}
      >
        <FaInfoCircle
          style={{ fontSize: '14px', marginLeft: '0.5rem', cursor: 'pointer' }}
        />
      </Link>
    );
    const header = (<span className="vertical-align">Suppress starting address {slideoverButton}</span>)
    suppressStartingInput = (
      <DopeFormGroup
        input={{ inputType: "checkbox", name: "suppress_starting", props: {
          headerText: header,
          size: "sm",
          className: "non-bold",
        }}}
        values={settings}
        onChange={actions.update}
        errors={settings.errors}
      />
    );
  }

  const isDopeUser = authApi.currentUserIsDopeUser();

  let maxContactsInput = null;
  if (isBlitzCampaign && isDopeUser) {
    maxContactsInput = (
      <div className="max-width-200">
        <DopeFormGroup
          input={{ inputType: "text", name: "max_contacts_per_generation", props: {
            label: "Maximum Generated Contacts Count",
            type: "number",
            max: 50000,
            min: 1,
          }}}
          values={settings}
          onChange={actions.update}
          errors={settings.errors}
        />
      </div>
    );
  }

  return (
    <div className="pad-l">

      {suppressStartingInput}

      <CampaignListsSelect
        headerText="I want to filter out the contacts from the following lists:"
        name="list_generation_setting_suppression_lists"
        values={settings}
        onChange={actions.update}
      />

      <CampaignListsSelect
        headerText="I want to include the contacts from the following lists:"
        name="list_generation_setting_seed_lists"
        values={settings}
        onChange={actions.update}
      />

      {maxContactsInput}

    </div>
  );
};

export default ExtraMailRules;
